html,
body {
  font-size: 16px;
  height: 100%;
  -webkit-text-size-adjust: none; /* Never autoresize text */
  overscroll-behavior-y: none;
}

body {
  --color-text-body: #5d7079;
  --site-vertical-padding: var(--size-32);

  @media (min-width: 769px) {
    --site-vertical-padding: var(--size-64);
  }
}

/** Neptune Docs – Layout  */
.site-container {
  --horizontal-padding: var(--size-16);

  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1800px;
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--horizontal-padding);
  padding-left: var(--horizontal-padding);

  @media (min-width: 769px) {
    --horizontal-padding: var(--size-32);
  }

  @media (min-width: 1025px) {
    --horizontal-padding: var(--size-40);
  }

  &--small {
    max-width: 1280px;
  }

  &--full-width {
    max-width: 100%;
  }
}

.site-body {
  padding-top: var(--size-64);
}

.site-main {
  width: 100%;
  min-width: 0; // https://stackoverflow.com/a/66689926
  padding-top: var(--site-vertical-padding);
  padding-bottom: calc(var(--site-vertical-padding) * 2);
}

.c-cards {
  margin-bottom: var(--size-24);

  @media (min-width: 769px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: var(--size-40);
  }
}

.c-main-content {
  h1 {
    margin: 0;
    margin-bottom: 12px;
    margin-bottom: var(--size-12, 12px);
  }

  .excerpt {
    font-size: 1.375rem;
  }

  h2 {
    margin-top: var(--size-24) !important;
    margin-bottom: var(--size-12);

    @media (min-width: 769px) {
      scroll-margin-top: calc(var(--size-64) * 2);
      margin-top: var(--size-64) !important;
    }
  }

  h3,
  h4 {
    margin-top: var(--size-24) !important;
    margin-bottom: var(--size-12);

    @media (min-width: 769px) {
      scroll-margin-top: calc(var(--size-64) * 2);
      margin-top: var(--size-48) !important;
    }
  }

  pre,
  code {
    overflow-wrap: break-word;
  }
}

table:not(.code-example table) {
  margin-bottom: 2em;
  overflow: auto;
  border-collapse: separate;
  width: 100%;

  th,
  td {
    text-align: left;
    vertical-align: top;
    line-height: 1.6;

    code {
      white-space: nowrap;
    }
  }

  th {
    padding: 5px 10px;
    border-bottom: 1px solid #ccc;
    vertical-align: bottom;
    color: var(--color-content-primary);
    font-weight: var(--font-weight-semi-bold);
  }

  td {
    padding: 10px;
  }

  tr:last-child {
    border-bottom: 1px solid #dddddd;
  }

  tr:nth-child(odd) > td {
    background-color: #f7f7f7;
  }

  tr:nth-child(even) > td {
    background-color: #fff;
  }
}
