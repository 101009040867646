$footerWideBreakpoint: 1025px;

.footer {
  padding: var(--site-vertical-padding) 0 var(--site-vertical-padding);
  background-color: var(--color-background-neutral);
  border-top: solid 1px var(--color-border-neutral);
}

.container {
  flex-direction: column;

  @media (min-width: $footerWideBreakpoint) {
    flex-direction: row;
    align-items: center;
  }
}

.navigation {
  margin-top: var(--size-24);

  @media (min-width: $footerWideBreakpoint) {
    margin-top: 0;
    margin-left: var(--size-32);

    ul {
      display: flex;
    }
  }
}

.navigationLink {
  display: inline-block;
  padding: var(--size-4) var(--size-8);
  text-decoration: none !important;
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-semi-bold);

  @media (min-width: $footerWideBreakpoint) {
    padding: var(--size-8) var(--size-12);
  }
}

.legal {
  display: block;

  @media (min-width: $footerWideBreakpoint) {
    text-align: right;
  }
}

.legalCopy {
  padding: var(--size-8) var(--size-8) 0;
  @media (min-width: $footerWideBreakpoint) {
    padding: var(--size-16) var(--size-12) 0;
  }
}
