.header {
  position: fixed;
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: var(--size-64);
  background-color: var(--color-background-elevated);
  border-bottom: solid 1px var(--color-border-neutral);
}

.logo {
  padding: var(--size-16) 0;
  @media (min-width: 769px) {
    padding: var(--size-16) var(--size-8);
  }
}

.menuButton {
  display: flex;
  align-items: center;
  margin-right: calc(var(--size-16) * -1);
  margin-bottom: 0;
  padding: var(--size-16);
  cursor: pointer;
  @media (min-width: 769px) {
    display: none;
  }
}

.navigation {
  @media (max-width: 768px) {
    --navigation-width: calc(100% - var(--size-64));

    position: fixed;
    right: calc(var(--navigation-width) * -1);
    overflow-y: auto;
    transition: transform 250ms ease-out;

    /* Mobile navigation – Open */
    .menuToggleCheckbox:checked ~ & {
      top: var(--size-64);
      z-index: 1;
      width: var(--navigation-width);
      height: calc(100vh - var(--size-64));
      background-color: var(--color-background-elevated);
      border-left: solid 1px var(--color-border-neutral);
      transform: translateX(-100%);
    }
  }

  @media (min-width: 769px) {
    display: flex;
    align-items: center;

    ul {
      display: inherit;
      align-items: inherit;
    }
  }
}

.subNavigation {
  border-top: 1px solid rgba(0, 0, 0, 0.102);
  border-top: 1px solid var(--color-border-neutral, rgba(0, 0, 0, 0.102));

  @media (min-width: 769px) {
    display: none !important;
  }

  .navigationLink {
    padding-left: var(--size-24);
  }
}

.navigationLink {
  display: block;
  padding: var(--size-8) var(--size-16);
  font-size: 1rem;
  font-weight: 400 !important;

  &[disabled] {
    color: var(--color-content-tertiary);
  }

  @media (min-width: 769px) {
    padding: 20px var(--size-12);
  }

  @media (min-width: 992px) {
    padding-right: var(--size-16);
    padding-left: var(--size-16);
  }
}

.navigationLinkActive {
  font-weight: 600 !important;

  @media (min-width: 769px) {
    padding-bottom: 17px;
    border-bottom: solid 3px var(--color-interactive-control);
    box-shadow: 0 1px 0 var(--color-interactive-control);
  }
}

.subNavigationTitle {
  display: block;
  padding: var(--size-12) var(--size-16) var(--size-4);
  color: var(--color-content-secondary);
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  text-transform: uppercase;
}
