.dynamic-flow {
  h1:not(.code-example h1) {
    margin: 0;
    margin-bottom: var(--size-12);
  }

  h2:not(.code-example h2) {
    margin-top: var(--size-24);
    margin-bottom: var(--size-12);

    @media (min-width: 769px) {
      scroll-margin-top: calc(var(--size-48) * 2);
      margin-top: var(--size-48);
    }
  }

  h3:not(.sandbox-preview h3, .code-example h3),
  h4:not(.sandbox-preview h4, .code-example h4) {
    margin-top: var(--size-24);
    margin-bottom: var(--size-12);

    @media (min-width: 769px) {
      scroll-margin-top: calc(var(--size-32) * 2);
      margin-top: var(--size-32);
    }
  }
}
